// load sass
import '../sass/common.scss';

// WPA バージョン管理用
window.addEventListener('load', () => {
  // console.log('Peek a boo!');

  // ユーザーログイン時のアイコンドロワーメニュー制御
  if(document.querySelector('.user_logo')){
    document.querySelector('.user_logo').onclick = () => {
      if (document.querySelector('.dropdown-menu').classList.contains('active')) {
        document.querySelector('.dropdown-menu').classList.remove('active')
      } else {
        document.querySelector('.dropdown-menu').classList.add('active')
      }
    }
  }
});
